/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function JourneyAlertsHomepage(props) {
  const { content, navigation, footer, overrides, ...rest } = props;
  const headerLogoOnClick = useNavigateAction({ type: "url", url: "/" });
  const byZipaboutOnClick = useNavigateAction({
    type: "url",
    url: "https://www.zipabout.com/",
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="700px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      className="JourneyAlertsHomepage"
      {...getOverrideProps(overrides, "JourneyAlertsHomepage")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="100px"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Header")}
      >
        <Image
          width="100px"
          height="100px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="contain"
          src="img/Logo_JourneyAlerts.svg"
          className="HeaderLogo"
          onClick={() => {
            headerLogoOnClick();
          }}
          {...getOverrideProps(overrides, "HeaderLogo")}
        ></Image>
        <Text
          fontFamily="Inter"
          fontSize="36px"
          fontWeight="700"
          color="rgba(0,113,187,1)"
          lineHeight="43.568180084228516px"
          textAlign="right"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Journey Alerts"
          {...getOverrideProps(overrides, "HeaderText")}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Main")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="100px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          children={navigation}
          {...getOverrideProps(overrides, "Navigation")}
        ></Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Content")}
        >
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="14.522727012634277px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            className="byzipabout"
            children="by Zipabout"
            onClick={() => {
              byZipaboutOnClick();
            }}
            {...getOverrideProps(overrides, "by Zipabout")}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="100px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        children={footer}
        {...getOverrideProps(overrides, "Footer")}
      ></Flex>
    </Flex>
  );
}
