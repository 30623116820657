import './App.css';
import { JourneyAlertsHomepage, StaticLink } from './ui-components';

function App() {
    return (
        <div className="App" >
            <JourneyAlertsHomepage
//                style={
//                    styles.JourneyAlertsHomepage
//                }
//                navigation={
//                    (
//                        <div>
//                            <StaticLink overrides={
//                                { "StaticLinkText": { children: "Home" } }
//                            }
//                                style={styles.StaticLink}
//                            />
//                            <StaticLink overrides={
//                                { "StaticLinkText": { children: "About Us" } }
//                            }
//                                style={styles.StaticLink}
//                            />
//                        </div>
//                    )
//                }
//                content={
//                    (
//                        <div>
//                            Content here
//                        </div>
//                    )
//                }
            />
        </div>
    );
}

const styles = {
    JourneyAlertsHomepage: { margin: 'auto', fontFamily: 'aktiv-grotesk,sans-serif' },
    StaticLink: { cursor: 'pointer' },
}

export default App;
